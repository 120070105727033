<template>
  <div class="others-content">
    <div
      class="others-content__container-date"
      v-for="dateFiles in filesArray"
      :key="dateFiles.date"
    >
      <label>{{ dateFiles.date.split("-").reverse().join(".") }}</label>
      <div class="others-content__files">
        <div
          class="others-content__file"
          v-for="file in dateFiles.items"
          :key="file.id"
          @click="downloadFile(file)"
        >
          <i class="icon-file"></i>
          <div class="others-content__wrap">
            <div class="others-content__label">{{ file.name }}</div>
            <div class="others-content__size">{{ sizeFloor(file.size) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { domainStorage } from "@/globalVariables";
import { mapActions } from "vuex";
import moment from "moment";
export default {
  name: "OthersContent",
  mixins: [],
  props: {
    files: {
      type: Array,
      required: true
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    ...mapActions("MessengerModule", ["setVideoPlayerData"]),
    returnSrcFile(src) {
      return domainStorage + "/chat/files/" + src;
    },
    downloadFile(file) {
      window.open(this.returnSrcFile(file.path), "_blank");
    },
    sizeFloor(size) {
      let tb = 1099511627776;
      let gb = 1073741824;
      let mb = 1048576;
      let kb = 1024;
      if (size >= tb) {
        return Math.floor(size / tb) + " TB";
      } else if (size >= gb && size < tb) {
        return Math.floor(size / gb) + " GB";
      } else if (size >= mb && size < gb) {
        return Math.floor(size / mb) + " MB";
      } else if (size >= kb && size < mb) {
        return Math.floor(size / kb) + " KB";
      } else {
        return size + " B";
      }
    },
    openModalPlayer(item) {
      this.setVideoPlayerData(item);
    }
  },
  computed: {
    filesArray() {
      return this.files
        .map((file) => {
          return {
            date: file.updated_at.split("T")[0],
            items: this.files.filter(
              (item) => item.updated_at.split("T")[0] === file.updated_at.split("T")[0]
            )
          };
        })
        .filter(
          (files, index, array) => index === array.findIndex((item) => files.date === item.date)
        )
        .sort(function (a, b) {
          return moment(b.date) - moment(a.date);
        });
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.others-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px;
  overflow-y: auto;
  margin-bottom: 60px;
  @media screen and (max-width: $xs) {
    margin-bottom: 0;
  }
  &__container-date {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 8px;
    label {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #a7a9c0;
      margin-bottom: 16px;
    }
  }
  &__files {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }
  &__file {
    display: flex;
    align-items: center;
    width: 49%;
    padding: 8px;
    margin-bottom: 6px;
    border-radius: 4px;
    cursor: pointer;
  }
  i {
    color: $text-highlight;
    margin-right: 8px;
  }
  &__wrap {
    display: flex;
    flex-direction: column;
    width: calc(100% - 25px);
  }
  &__label {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: $text-default;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__size {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    color: $text-disabled;
  }
}
</style>
